import appConfig from 'config/app.config';

/**
 * Send API request to backend 
 * @param {string} endpoint 
 * @param {object} body 
 * @returns 
 */
const apiHelper = (endpoint, body) => {
	const url = appConfig.apiURL + endpoint;
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	const fetchConfig = {
		method: 'POST',
		mode: 'cors',
		headers: myHeaders,
		cache: 'default',
		body: JSON.stringify({...body, env: appConfig.env})
	};

	return new Promise((resolve) => {
		fetch(url, fetchConfig).then(
			(response)=>{
				if (response) {
					response.json().then(
						(data) => {
							resolve(data);
						},
						(rejection) => {
							console.error('api rejection: ' + rejection);
							resolve({status: 'error', error: rejection.error});
						}
					);
				} else {
					resolve({status: 'error', error: response});
				}
			},
			(error)=>{
				resolve({status: 'error', error: error});
			}
		);
	});
};

export {
	apiHelper
};
